<template>
  <div>
    <el-button
      type="text"
      size="mini"
      icon="fas fa-cog"
      @click="$refs.editScaleDialog.open({scale: data})"
    >
      Настроить
    </el-button>

    <card-element-configurable-scale-edit-dialog
      ref="editScaleDialog"
      :disabled="disabled"
      @close="$emit('elements-changed');"
    >
    </card-element-configurable-scale-edit-dialog>
  </div>
</template>

<script>

import CardElementConfigurableScaleEditDialog
  from "@/components/configurableScales/settings/CardElementConfigurableScaleEditDialog.vue";
import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "quality-card-plan-view",
  mixins: [dynamicScaleComponent],
  components: {CardElementConfigurableScaleEditDialog, ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false},
    row:{type: Object},
  },
  data() {
    return {
      scale:{
        plan: null,
      },
    }
  },
  methods: {}
}
</script>